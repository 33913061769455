<template>
  <section>
    <div style="margin-bottom: 20px" class="d-flex container690">
      <!-- 返回 -->
      <el-button
        icon="el-icon-arrow-left"
        style="color: #50b5ff"
        @click="$router.back()"
        >{{ $t("Events.024@back") }}
      </el-button>
      <!-- 判断是否注册 -->
      <el-alert
        v-if="!is_register"
        style="color: #ffba68"
        show-icon
        center
        :title="$t('Events.be4@you_have_not_registe')"
        type="warning"
        class="ml-3"
      >
      </el-alert>
    </div>
    <div class="program container690">
      <div v-if="!Array.isArray(allProgram)">
        <el-card>
          <div class="program-header d-flex justify-content-between">
            <div class="program-btn">
              <el-button
                :type="index ? 'primary' : 'info'"
                plain
                :disabled="!index"
                style="border: none"
                @click="changeIndex(0)"
                >{{ $t("Events.99e@previous") }}</el-button
              >
            </div>
            <div class="d-flex">
              <i class="ri-calendar-2-line"></i>
              <p class="m-0">
                {{ Object.keys(this.allProgram)[index] }}
                <span style="color: #b1b6ba"
                  >({{
                    allProgram[Object.keys(allProgram)[index]] &&
                    allProgram[Object.keys(allProgram)[index]][0].time_zone_2
                  }})</span
                >
              </p>
            </div>
            <div class="program-btn">
              <el-button
                :type="
                  index != Object.keys(this.allProgram).length - 1
                    ? 'primary'
                    : 'info'
                "
                plain
                :disabled="index == Object.keys(this.allProgram).length - 1"
                style="border: none; width: 90px"
                @click="changeIndex(1)"
                >{{ $t("Events.b72@next") }}</el-button
              >
            </div>
          </div>
        </el-card>
        <el-card style="margin-top: 20px">
          <section
            v-for="(v, i) in allProgram[Object.keys(this.allProgram)[index]]"
            :key="i"
            class="program-content"
          >
            <p class="m-0">
              {{ v.time1 }}
              <span style="color: #b1b6ba">({{ v.time_zone_2 }})</span>
            </p>
            <ul class="p-0 program-list">
              <li>
                <h3 style="cursor: pointer" @click="toProgramDetail(v)">
                  {{ v.program_title }}
                  <span
                    :class="{
                      MeetingTag: v.model_type == 4,
                      EventTag: v.model_type == 2,
                      courseTag: v.model_type == 3,
                      projectTag: v.model_type == 1,
                      noneTag: v.model_type == 0,
                    }"
                    class="tag"
                    v-if="v.is_cron"
                  >
                    {{ judgeCurrentType(v) }}
                  </span>
                </h3>
                <span
                  ><i class="ri-time-line"></i>{{ v.time }}
                  <span style="color: #b1b6ba">({{ v.time_zone }})</span>
                </span>
                <section v-if="!(v.model_type == 3 && v.package_type == 1)">
                  <span v-if="v.type == 3">
                    <i class="ri-map-pin-line"></i>{{ v.venue }}{{ v.room }} /
                    Online
                  </span>
                  <span v-else-if="v.type != 2 && v.room"
                    ><i class="ri-map-pin-line"></i>{{ v.venue
                    }}{{ v.room }}</span
                  >
                  <span v-else-if="v.type_desc"
                    ><i class="ri-map-pin-line"></i>{{ v.type_desc }}</span
                  >
                </section>
                <span
                  ><i class="ri-lock-unlock-line"></i
                  >{{ Object.values(v.attendee_tags).join("，") }}
                </span>
                <span v-if="v.special_guest.length"
                  ><i class="ri-user-3-line"></i
                  >{{ v.special_guest.map((n) => n.name).join("，") }}</span
                >
              </li>
            </ul>
          </section>
        </el-card>
      </div>
      <div v-else class="container690">
        <el-card>
          <div style="background:#fff;">
            <img
              src="@/assets/images/group/empty1.png"
              alt=""
              style="width:250px;margin: 100px auto; display: block"
            />
          </div>
        </el-card>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {};
  },

  components: {},

  computed: {
    ...mapState({
      is_register: (state) => {
        return state.Process.is_register;
      },
      allProgram: (state) => {
        return state.Process.all_program;
      },
      index: (state) => {
        if (state.Process.tabIndex >= 1) {
          return state.Process.tabIndex;
        } else {
          return 0;
        }
      },
      typeId: (state) => state.Process.typeId,
    }),
  },
  filters: {
    jumpModelFilter(val) {
      // console.log('cal', val);
      return val == 1
        ? vm.$t("Events.135@events")
        : val == 2
        ? "Event"
        : val == 3
        ? "Course"
        : val == 4
        ? "Meeting"
        : "";
    },
  },
  created() {
    let type = this.$route.query.type;
    let ajaxType = {
      1: "projectProgram",
      2: "eventProgram",
      3: "courseProgram",
      4: "meetingProgram",
    };
    this.projectProgram([
      {
        [this.typeId[type]]: this.$route.query.id,
        type: 1,
        time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      ajaxType[type],
    ]);
  },

  methods: {
    ...mapMutations(["changeIndex"]),
    ...mapActions(["projectProgram"]),
    judgeCurrentType(item) {
      let type = item.model_type;
      return type == 1
        ? "Project"
        : type == 2
        ? "Event"
        : type == 3
        ? "Course"
        : type == 4
        ? "Meeting"
        : "";
    },
    toProgramDetail(item) {
      let path = {
        1: "/project-detail",
        2: "/event-detail",
        3: "/course-detail",
        4: "/meeting-details",
      };
      if (item.is_cron) {
        this.$router.push({
          path: path[item.model_type],
          query: {
            id: item.model_id,
            type: item.model_type,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .program-header {
    display: flex;
    align-items: center;

    > div i {
      font-size: 16px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    > div p {
      color: #666;
      font-size: 16px;
    }

    > div.program-btn {
      /* display: flex;
      flex-grow: 1;
      justify-content: flex-end; */

      span {
        display: block;
        width: 90px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #f5fbff;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 15px;
      color: #666;
    }

    .program-list {
      > li {
        padding: 10px;
        padding-left: 64px;
        position: relative;
        display: flex;
        flex-direction: column;

        &::before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 14px;
          left: 26px;
        }

        > h3 {
          font-size: 16px;
          color: #333;

          > .tag {
            padding: 0 12px;
            color: #6fc284;
            background-color: #f0f9f2;
            margin-left: 10px;
          }

          .MeetingTag {
            background-color: #ecf0f7;
            color: #3a67b1;
          }

          .EventTag {
            background-color: #f0f9f2;
            color: #6fc284;
          }

          .courseTag {
            background-color: #e6f4fb;
            color: #0097d7;
          }

          .projectTag {
            background-color: #eef9f8;
            color: #53c5b8;
          }

          .noneTag {
            display: none;
          }
        }

        span {
          color: #666;
          padding: 8px 0;
          line-height: 1;

          > i {
            font-size: 17px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .el-button{
    font-size: 8px;
  }
  .program-header {
    display: flex;
    align-items: center;

    > div i {
      font-size: 8px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    > div p {
      color: #666;
      font-size: 8px;
    }

    > div.program-btn {
      /* display: flex;
      flex-grow: 1;
      justify-content: flex-end; */

      span {
        display: block;
        width: 40px;
        height: 20px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #f5fbff;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 8px;
      color: #666;
    }

    .program-list {
      > li {
        padding: 8px;
        padding-left: 30px;
        position: relative;
        display: flex;
        flex-direction: column;

        &::before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 7px;
          left: 13px;
        }

        > h3 {
          font-size: 8px;
          color: #333;

          > .tag {
            padding: 0 12px;
            color: #6fc284;
            background-color: #f0f9f2;
            margin-left: 10px;
          }

          .MeetingTag {
            background-color: #ecf0f7;
            color: #3a67b1;
          }

          .EventTag {
            background-color: #f0f9f2;
            color: #6fc284;
          }

          .courseTag {
            background-color: #e6f4fb;
            color: #0097d7;
          }

          .projectTag {
            background-color: #eef9f8;
            color: #53c5b8;
          }

          .noneTag {
            display: none;
          }
        }

        span {
          color: #666;
          padding: 8px 0;
          line-height: 1;

          > i {
            font-size: 8px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
</style>