var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"d-flex container690",staticStyle:{"margin-bottom":"20px"}},[_c('el-button',{staticStyle:{"color":"#50b5ff"},attrs:{"icon":"el-icon-arrow-left"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(_vm._s(_vm.$t("Events.024@back"))+" ")]),(!_vm.is_register)?_c('el-alert',{staticClass:"ml-3",staticStyle:{"color":"#ffba68"},attrs:{"show-icon":"","center":"","title":_vm.$t('Events.be4@you_have_not_registe'),"type":"warning"}}):_vm._e()],1),_c('div',{staticClass:"program container690"},[(!Array.isArray(_vm.allProgram))?_c('div',[_c('el-card',[_c('div',{staticClass:"program-header d-flex justify-content-between"},[_c('div',{staticClass:"program-btn"},[_c('el-button',{staticStyle:{"border":"none"},attrs:{"type":_vm.index ? 'primary' : 'info',"plain":"","disabled":!_vm.index},on:{"click":function($event){return _vm.changeIndex(0)}}},[_vm._v(_vm._s(_vm.$t("Events.99e@previous")))])],1),_c('div',{staticClass:"d-flex"},[_c('i',{staticClass:"ri-calendar-2-line"}),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(Object.keys(this.allProgram)[_vm.index])+" "),_c('span',{staticStyle:{"color":"#b1b6ba"}},[_vm._v("("+_vm._s(_vm.allProgram[Object.keys(_vm.allProgram)[_vm.index]] && _vm.allProgram[Object.keys(_vm.allProgram)[_vm.index]][0].time_zone_2)+")")])])]),_c('div',{staticClass:"program-btn"},[_c('el-button',{staticStyle:{"border":"none","width":"90px"},attrs:{"type":_vm.index != Object.keys(this.allProgram).length - 1
                  ? 'primary'
                  : 'info',"plain":"","disabled":_vm.index == Object.keys(this.allProgram).length - 1},on:{"click":function($event){return _vm.changeIndex(1)}}},[_vm._v(_vm._s(_vm.$t("Events.b72@next")))])],1)])]),_c('el-card',{staticStyle:{"margin-top":"20px"}},_vm._l((_vm.allProgram[Object.keys(this.allProgram)[_vm.index]]),function(v,i){return _c('section',{key:i,staticClass:"program-content"},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(v.time1)+" "),_c('span',{staticStyle:{"color":"#b1b6ba"}},[_vm._v("("+_vm._s(v.time_zone_2)+")")])]),_c('ul',{staticClass:"p-0 program-list"},[_c('li',[_c('h3',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toProgramDetail(v)}}},[_vm._v(" "+_vm._s(v.program_title)+" "),(v.is_cron)?_c('span',{staticClass:"tag",class:{
                    MeetingTag: v.model_type == 4,
                    EventTag: v.model_type == 2,
                    courseTag: v.model_type == 3,
                    projectTag: v.model_type == 1,
                    noneTag: v.model_type == 0,
                  }},[_vm._v(" "+_vm._s(_vm.judgeCurrentType(v))+" ")]):_vm._e()]),_c('span',[_c('i',{staticClass:"ri-time-line"}),_vm._v(_vm._s(v.time)+" "),_c('span',{staticStyle:{"color":"#b1b6ba"}},[_vm._v("("+_vm._s(v.time_zone)+")")])]),(!(v.model_type == 3 && v.package_type == 1))?_c('section',[(v.type == 3)?_c('span',[_c('i',{staticClass:"ri-map-pin-line"}),_vm._v(_vm._s(v.venue)+_vm._s(v.room)+" / Online ")]):(v.type != 2 && v.room)?_c('span',[_c('i',{staticClass:"ri-map-pin-line"}),_vm._v(_vm._s(v.venue)+_vm._s(v.room))]):(v.type_desc)?_c('span',[_c('i',{staticClass:"ri-map-pin-line"}),_vm._v(_vm._s(v.type_desc))]):_vm._e()]):_vm._e(),_c('span',[_c('i',{staticClass:"ri-lock-unlock-line"}),_vm._v(_vm._s(Object.values(v.attendee_tags).join("，"))+" ")]),(v.special_guest.length)?_c('span',[_c('i',{staticClass:"ri-user-3-line"}),_vm._v(_vm._s(v.special_guest.map(function (n) { return n.name; }).join("，")))]):_vm._e()])])])}),0)],1):_c('div',{staticClass:"container690"},[_c('el-card',[_c('div',{staticStyle:{"background":"#fff"}},[_c('img',{staticStyle:{"width":"250px","margin":"100px auto","display":"block"},attrs:{"src":require("@/assets/images/group/empty1.png"),"alt":""}})])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }